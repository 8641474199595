export const infoCategories = [
	{ name: 'Canvas Art Prints', link: '/canvas-art-prints' },
	{
		name: 'About Us',
		link: '/about-us',
	},
	{
		name: 'Sustenability',
		link: '/sustenabilitate',
	},
	{
		name: 'Inspiration',
		link: '/inspiration',
	},
	{
		name: 'Terms & Conditions',
		link: '/termeni-și-condiții',
	},
	{
		name: 'Privacy Policy',
		link: '/politica-de-confidențialitate',
	},
	{
		name: 'FAQ',
		link: '/faq',
	},
	{
		name: 'Contact',
		link: '/contact',
	},
];
