export const articles = [
	{
		imgTitle: 'giftguide.webp',
		title: 'Holiday Gift Guide',
		date: '26th November 2024',
		urlTitle: 'gift-guide',
		description:
			'Get inspired with a selection of our favourite posters to gift your loved ones – including yourself – during the holiday season.',
	},
];
